import './MainBlcok.scss'
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {GetAbout, sendSubscribe} from "../../../redux/actions";
import {connect} from "react-redux";
import {useEffect,useState} from "react";
import {generateFileMediaUrl} from "../../../utils/generateMediaUrl";
import isEmail from "validator/es/lib/isEmail";
import {LoadingOutlined} from '@ant-design/icons';
import {InputGroup} from "../../uiElements/inputGroup/InputGroup";

function MainBlock(props) {
    const {staticTexts,home,requestLoading,about} = props
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);

    const getInputValues = (e) => {
        const {name, value} = e.target;
        setEmail(value);
        setError(false)
    };

    const sendSubscribe = () => {
        if (email && isEmail(email)) {
            props.sendSubscribe({email}).then(() => {
                setEmail(email)
            }, errorEmail => setError(!errorEmail)).finally(() => {
                setEmail('')
            })
        } else {
            setError(true)
        }
    }
    useEffect(()=> {
        setEmail("")
        setError(false)
    },[requestLoading])


    return <div className={'mainBlock-wrapper'}>
        <div className={'img-wrapper'}>
            <img src={generateFileMediaUrl(home?.mediaMain?.path)} alt=""/>
        </div>
        <div className={'mainBlock-info-wrapper'}>
            <div className={'mainBlock-title'}>
                {home?.title}
            </div>
            <div className={'mainBlock-description'}>
                {home?.description}
            </div>
            {/*<div className={`main-input-wrapper ${error ? 'invalid' : ''}`}>*/}
            {/*    <InputGroup*/}
            {/*        inputType={'input'}*/}
            {/*        type={"text"}*/}
            {/*        value={email}*/}
            {/*        name={'email'}*/}
            {/*        placeholder={staticTexts?.homepage_contact_placeholder_email}*/}
            {/*        maxLength={100}*/}
            {/*        onChange={getInputValues}*/}
            {/*    />*/}

            {/*    <button className='sent-email'*/}
            {/*            onClick={() => {*/}
            {/*                if (!requestLoading && sendSubscribe) {*/}
            {/*                    sendSubscribe()*/}
            {/*                }*/}
            {/*            }}>*/}
            {/*        {staticTexts?.subscribe_btn}*/}
            {/*        {*/}
            {/*            requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>*/}
            {/*                : ''*/}
            {/*        }*/}

            {/*    </button>*/}
            {/*</div>*/}
        </div>
    </div>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'about',
    ])
};
const mapDispatchToProps = {
    sendSubscribe,
    GetAbout
};
export default connect(mapStateToProps,mapDispatchToProps)(MainBlock);