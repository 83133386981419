// import packages
import React from "react";
import {connect} from "react-redux";
import {Link} from 'react-router-dom'

// Import assets
import './footer.scss'

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetContacts} from "../../redux/actions";
import {Logo} from "../../assets/images";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";


function Footer(props) {
    const {contacts, staticTexts} = props;

    function downloadPresentation(pdf) {
        console.log(pdf,'pdf')
        if (pdf) {
            fetch(generateImageMediaUrl(pdf?.path))
                .then(response => {
                    response.blob().then(blob => {
                        let a = document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);
                        a.download = `${pdf.name || 'presentation'}`;
                        a.click();
                    });
                });
        }
    }

    return <footer className='footer-wrapper'>
        <div className="desktop-footer-wrapper">
            <div className="top-part">
                <div className="col">
                    <div className="logo-wrapper">
                        <Logo title={''}/>
                    </div>
                    {contacts?.presentationFile && <div className={'text-wrapper presentation-button'} onClick={() => {downloadPresentation(contacts?.presentationFile)}}>
                        {staticTexts?.footer_section_button_presentation || 'Ներբեռնեք ներկայացումը'}
                    </div>}
                </div>

                <div className={'right-block'}>
                    <div className="col">
                        <div className="title">{staticTexts?.footer_section_sites_title}</div>
                        <Link to={`/`}>{staticTexts?.footer_section_home}</Link>
                        <Link to={`/about`}>{staticTexts?.footer_section_about}</Link>
                        <Link to={`/news`}>{staticTexts?.footer_section_news}</Link>
                        {/*<Link to={`/products`}>{staticTexts?.footer_section_products}</Link>*/}
                        <Link to={`/contact`}>{staticTexts?.footer_section_contact}</Link>
                    </div>
                    <div className="col">
                        <div className="title">{staticTexts?.footer_section_contact}</div>
                        <Link to={`/`} >
                            <span className={'item-description'}>{staticTexts?.footer_section_subscription}</span>
                        </Link>
                        <Link to={`/`}>
                            <span className={'item-description'}>{staticTexts?.footer_section_number}</span>
                        </Link>
                        <Link to={`/`} >
                            <span className={'item-description'}>{staticTexts?.footer_section_feedback}</span>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="bottom-part">
                <div className="copyright">{staticTexts?.footer_copyright}</div>
                <div className='developed-by'>
                    <div className={'design'}>
                        {staticTexts?.footer_section_developed_by_text} <a href={'https://solidy.am/'} target={"_blank"}>Solidy</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'selectedLanguage',
        'services'
    ])
};

const mapDispatchToProps = {GetContacts};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
